<template>
  <!-- eslint-disable -->
  <div>
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <b-card no-body>
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-form class="p-1">
            <b-row class="mb-1">
              <b-col md="12">
                <h5>Descripción</h5>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="6" class="d-flex flex-column">
                <!-- <validation-provider #default="{ errors }" name="id-restriction" rules="requeridoE">
                  <b-form-group label="ID Restricción" label-for="id-restriction">
                    <b-form-input
                      v-model="items.code"
                      id="id-restriction"
                      placeholder="ID Restricción"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider> -->
                <validation-provider #default="{ errors }" name="description" rules="requeridoE">
                  <b-form-group label="Descripción" label-for="description">
                    <b-form-input
                      v-model="items.description"
                      id="description"
                      placeholder="Descripción"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="">
                <validation-provider #default="{ errors }" name="detail" rules="">
                  <b-form-group label="Detalle" label-for="detail" class="mb-0">
                    <b-form-textarea
                      id="detail"
                      placeholder="Detalle"
                      rows="4"
                      max-rows="6"
                      v-model="items.detail"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12">
                <h5>Especialidad y Tipo</h5>
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="speacility" rules="requeridoE">
                  <b-form-group label="Especialidad" label-for="speacility">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="especialidades"
                      label="code"
                      input-id="speacility"
                      :reduce="(especialidades) => especialidades.id"
                      v-model="items.speciality_id"
                      placeholder="Especialidad"
                      @input="selectSpeciality(items.speciality_id)"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="priority" rules="requeridoE">
                  <b-form-group label="Prioridad" label-for="priority">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="prioridades"
                      label="name"
                      input-id="priority"
                      :reduce="(records) => records.name"
                      v-model="items.priority"
                      placeholder="Prioridad"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <!-- <validation-provider #default="{ errors }" name="type-item" rules="">
                  <b-form-group label="Tipo Item" label-for="type-item">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="records"
                      label="name"
                      input-id="type-item"
                      :reduce="(records) => records.id"
                      v-model="items.item_type"
                      placeholder="Tipo Item"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider> -->
                <validation-provider #default="{ errors }" name="category" rules="requeridoE">
                  <b-form-group label="Categoria" label-for="category">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="categorias"
                      label="code"
                      input-id="category"
                      :reduce="(categorias) => categorias.id"
                      v-model="items.category_id"
                      placeholder="Categoria"
                      @input="changeCate(items.category_id)"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
                <validation-provider #default="{ errors }" name="sub-category" rules="">
                  <b-form-group label="Sub-Categoria" label-for="sub-category">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="subcategorias"
                      label="code"
                      input-id="sub-category"
                      :reduce="(subcategorias) => subcategorias.id"
                      v-model="items.sub_category_id"
                      placeholder="Sub-Categoria"
                      @input="changeSubCate(items.sub_category_id)"
                    >
                      <template v-slot:selected-option="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                      <template slot="option" slot-scope="option">
                        {{ option.code }} - {{ option.description }}
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6" class="d-flex flex-column">
                <validation-provider #default="{ errors }" name="type" rules="requeridoE">
                  <!-- <b-form-group label="Tipo" label-for="type">
                    <v-select
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="types"
                      label="text"
                      input-id="type"
                      :reduce="(types) => types.value"
                      v-model="items.type"
                      placeholder="Tipo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group> -->

                  <b-form-group label="Tipo" label-for="type" class="h-100">
                    <div class="d-flex w-50 align-items-center mt-1 ml-1">
                      <span class="mr-2">Campo</span>
                      <b-form-checkbox v-model="items.type" name="type" switch >                       
                      </b-form-checkbox>                       
                       <span class="ml-2">Crítico</span>
                    </div>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>

                </validation-provider>
              </b-col>
              <b-row class="mb-1">
                <b-col md="6" class="d-flex flex-column">

                  <validation-provider #default="{ errors }" name="critic_route" rules="requeridoE">
                    <b-form-group label-for="critic_route" class="h-100">
                      <div class="d-flex w-50 align-items-center mt-1 ml-1">
                        <span class="mr-2">¿Ruta Crítica?</span>
                        <b-form-checkbox v-model="items.critic_route" name="critic_route" >                       
                        </b-form-checkbox>                       
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6" class="d-flex flex-column">
                  <validation-provider #default="{ errors }" name="critic_route" rules="">
                    <b-form-group v-show="items.critic_route"  label-for="id_p6" class="h-100 w-200" >
                      <div class="d-flex w-200 align-items-center mt-1 ml-1">
                        <span class="mr-2" style="width: 65px;">ID P6</span>
                        <b-form-input
                          v-model="items.id_p6"
                          id="id_p6"
                          placeholder="ID P6"
                          style="width: 300px;"
                        />                    
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import CategoryService from '@/services/CategoryService'
import SubCategoryService from '@/services/SubCategoryService'
import SpecialityService from '@/services/SpecialityService'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  props: ['edit', 'project_id'],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      showLoading: false,
      tabIndex: 0,
      records: [],
      project: null,
      prioridades: [
        {
          id: 1,
          name: 'Alta'
        },
        {
          id: 2,
          name: 'Media'
        },
        {
          id: 3,
          name: 'Baja'
        }
      ],
      types:[
        {
          text: 'Campo',
          value: 'campo'
        },
        {
          text: 'Critico',
          value: 'critica'
        },
      ],
      categorias: [],
      subcategorias: [],
      subcategory: [],
      especialidades: [],
      leadTime: '',
      items: {
        code: '',
        description: '',
        detail: '',
        speciality_id: null,
        priority: '',
        item_type: '',
        category_id: null,
        sub_category_id: null,
        type: false,
        critic_route: false,
        id_p6: null
      }
    }
  },
  async mounted() {
    const respSubCategory = await SubCategoryService.getSubCategorys('', this.$store)
    console.log('respSubCategory', respSubCategory)
    if (respSubCategory.status) {
      this.subcategory = respSubCategory.data.rows
    }
    if (!this.edit) {
      this.getData()
    }
    
  },
  // created() {
  //   this.getData()
  // },
  methods: {
    async getData() {
      this.showLoading = true

      const idRoute = this.edit ? Number(this.$parent.$parent.$parent.$parent.dataItems.project_id) : Number(this.$route.params.id)
      this.showLoading = true
      let url =
        `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: idRoute }])

      const respCategory = await CategoryService.getCategorys(url, this.$store)

      if (respCategory.status) {
        this.categorias = respCategory.data.rows
      }
      let url1 =
        `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: idRoute }])

      const resSpec = await SpecialityService.getSpecialitys(url1, this.$store)
      if (resSpec.status) {
        this.especialidades = resSpec.data.rows
      }

      //EDITAR
      if (this.edit) {
        if (this.$parent.$parent.$parent.$parent.dataItems.category_id != null) {
          this.changeCate(this.$parent.$parent.$parent.$parent.dataItems.category_id)
        }
        if (this.$parent.$parent.$parent.$parent.dataItems.sub_category_id != null) {
          this.changeSubCate(this.$parent.$parent.$parent.$parent.dataItems.sub_category_id)
        }
      }
      this.showLoading = false
    },
    async changeCate(id) {
      if (this.categorias.length > 0) {

        const lead = this.categorias.filter((e) => e.id == id)
        this.$emit('lead', lead.length > 0 ? lead[0].leadtime : '21')
      }
      this.subcategorias = []
      if (this.subcategory.length > 0) {
        const subcates = this.subcategory.filter((e) => e.category.id == id)
 
        if (subcates.length > 0) {
          this.subcategorias = subcates
        }
      }
    },
    changeSubCate(id) {
 
      if (id != null) {

        const lead = this.subcategory.filter((e) => e.id == id)
        this.$emit('lead', lead.length > 0 ? lead[0].leadtime : '21')
      } else {
        this.changeCate(this.items.category_id)
      }
    },
    selectSpeciality(id) {
      // this.$emit('selectSpe', id)
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
