var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"variant":"white","show":_vm.showLoading,"spinner-variant":"primary","blur":"0","opacity":".75","rounded":"sm"}},[_c('b-card',{attrs:{"no-body":""}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"p-1"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('h5',[_vm._v("Descripción")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"description","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Descripción","label-for":"description"}},[_c('b-form-input',{attrs:{"id":"description","placeholder":"Descripción"},model:{value:(_vm.items.description),callback:function ($$v) {_vm.$set(_vm.items, "description", $$v)},expression:"items.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"detail","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mb-0",attrs:{"label":"Detalle","label-for":"detail"}},[_c('b-form-textarea',{attrs:{"id":"detail","placeholder":"Detalle","rows":"4","max-rows":"6"},model:{value:(_vm.items.detail),callback:function ($$v) {_vm.$set(_vm.items, "detail", $$v)},expression:"items.detail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',[_vm._v("Especialidad y Tipo")])])],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"speacility","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Especialidad","label-for":"speacility"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.especialidades,"label":"code","input-id":"speacility","reduce":function (especialidades) { return especialidades.id; },"placeholder":"Especialidad"},on:{"input":function($event){return _vm.selectSpeciality(_vm.items.speciality_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.speciality_id),callback:function ($$v) {_vm.$set(_vm.items, "speciality_id", $$v)},expression:"items.speciality_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"priority","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Prioridad","label-for":"priority"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.prioridades,"label":"name","input-id":"priority","reduce":function (records) { return records.name; },"placeholder":"Prioridad"},model:{value:(_vm.items.priority),callback:function ($$v) {_vm.$set(_vm.items, "priority", $$v)},expression:"items.priority"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"category","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Categoria","label-for":"category"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.categorias,"label":"code","input-id":"category","reduce":function (categorias) { return categorias.id; },"placeholder":"Categoria"},on:{"input":function($event){return _vm.changeCate(_vm.items.category_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.category_id),callback:function ($$v) {_vm.$set(_vm.items, "category_id", $$v)},expression:"items.category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"sub-category","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Sub-Categoria","label-for":"sub-category"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.subcategorias,"label":"code","input-id":"sub-category","reduce":function (subcategorias) { return subcategorias.id; },"placeholder":"Sub-Categoria"},on:{"input":function($event){return _vm.changeSubCate(_vm.items.sub_category_id)}},scopedSlots:_vm._u([{key:"selected-option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.code)+" - "+_vm._s(option.description)+" ")]}}],null,true),model:{value:(_vm.items.sub_category_id),callback:function ($$v) {_vm.$set(_vm.items, "sub_category_id", $$v)},expression:"items.sub_category_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"type","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"h-100",attrs:{"label":"Tipo","label-for":"type"}},[_c('div',{staticClass:"d-flex w-50 align-items-center mt-1 ml-1"},[_c('span',{staticClass:"mr-2"},[_vm._v("Campo")]),_c('b-form-checkbox',{attrs:{"name":"type","switch":""},model:{value:(_vm.items.type),callback:function ($$v) {_vm.$set(_vm.items, "type", $$v)},expression:"items.type"}}),_c('span',{staticClass:"ml-2"},[_vm._v("Crítico")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"critic_route","rules":"requeridoE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"h-100",attrs:{"label-for":"critic_route"}},[_c('div',{staticClass:"d-flex w-50 align-items-center mt-1 ml-1"},[_c('span',{staticClass:"mr-2"},[_vm._v("¿Ruta Crítica?")]),_c('b-form-checkbox',{attrs:{"name":"critic_route"},model:{value:(_vm.items.critic_route),callback:function ($$v) {_vm.$set(_vm.items, "critic_route", $$v)},expression:"items.critic_route"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-col',{staticClass:"d-flex flex-column",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"critic_route","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.critic_route),expression:"items.critic_route"}],staticClass:"h-100 w-200",attrs:{"label-for":"id_p6"}},[_c('div',{staticClass:"d-flex w-200 align-items-center mt-1 ml-1"},[_c('span',{staticClass:"mr-2",staticStyle:{"width":"65px"}},[_vm._v("ID P6")]),_c('b-form-input',{staticStyle:{"width":"300px"},attrs:{"id":"id_p6","placeholder":"ID P6"},model:{value:(_vm.items.id_p6),callback:function ($$v) {_vm.$set(_vm.items, "id_p6", $$v)},expression:"items.id_p6"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }